<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="store" :query="{  }">
   
    </Mural>

    <main class="lesson-content q-my-lg">
      <div  class="content-table row flex q-pt-xs">
        <div class="column col-12">
            <Table
              class="full-height"
              :list="orders"
              :columns="columnsTable"              
              :addEdit="false"
              :addRemove="false"
              :addView="true"
              @view="(item) => view(item)"
          >
          <template v-slot:body="props">
              <div>
                <q-btn
                  dense
                  round
                  color="default-pink"
                  title="Visualizar"
                  flat
                  @click="() => view(props.props.row)"
                  icon="visibility"
                />
              </div>
            </template>
          </Table>
        </div>
      </div>

    </main>
    <q-inner-loading
        :showing="loadingPage"
        label="Por favor aguarde..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
    />
  </div>

</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import WareHouseService from '@/services/WareHouseService';
import Table from "@/components/shared/tables/TableDynamic.vue";
import {ref, onMounted} from "vue";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";
export default {
  name: "CancelOrder",
  components: {
    Mural,
    DefaultBtn,
    Table,  
  },
  setup() {
    let _wareHouseService = new WareHouseService();
    let breadcrumbs = ref(["Cancelamentos"]);
    let orders = ref([]);
    let router = new useRouter();
    let loadingPage = ref(false);

    let columnsTable = ref([
      {name: 'cancel_id', align: 'left', label: 'ID', field: 'cancel_id', sortable: false},
      {name: 'date_sale', align: 'left', label: 'Data da Solicitação', field: 'date_sale', sortable: false},
      {name: 'total', align: 'left', label: 'Valor do Pedido', field: 'total', sortable: false},
      {name: 'description_status', align: 'left', label: 'Status Atual', field: 'description_status', sortable: false},
      {name: 'sales_order_status', align: 'left', label: 'Status da Venda', field: 'sales_order_status', sortable: false},
      {name: 'reason_description', align: 'left', label: 'Motivo do Cancelamento', field: 'reason_description', sortable: false},
      {name: 'acao', align: 'center', label: 'Ações', field: 'acoes', sortable: false},
    ])

    function view(order){
      router.push({ name: "order-cancel-details", query: { orderId: order.sales_order_id, cancelId: order.cancel_id } });
    }

    async function _getOrders(){
      loadingPage.value = true;
      let data = await _wareHouseService.getSolicitionCancelOrder();
      orders.value = data.data;
      loadingPage.value = false;
    }

    onMounted(() => {
      _getOrders();
    });

 

    return {
      breadcrumbs,
      orders,
      loadingPage,
      columnsTable,
      view
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}
.content-card {
  Width: 100%;
  Height: 206px;
  background: #F7F7F7;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.main-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}

.content {

  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0.07em;
  color: #9C9C9C;

  .bold_number {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000 !important;
  }
}

.image-groups {
  border-radius: 20px;
}

.card-btn {
  box-shadow: 3px 10px 15px #00000033;
  width: 100px;
}


.center{
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.description-product{
  max-height: 6rem;
  overflow: auto;
  border-radius: 0 !important;
}

.card-icon-finished{
  margin-top: 1.2rem;
  width: 61px;
  height: 61px;
  background: #daf8e1;
  font-style: normal;
  border-radius: 30px !important;
}
.rounder-icon{
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #45DC67;
}

.text-title-dialog{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
}
</style>